import styled from "styled-components";
import Link from "next/link";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ContentBox = styled.div`
  text-align: center;
  padding: 40px 60px;
`;

const Message = styled.p`
  margin-top: 20px;
`;

const FunFact = styled.p`
  font-style: italic;
  margin-top: 20px;
`;

const Custom404: React.FC = () => {
  return (
    <Wrapper>
      <ContentBox>
        <h2>404 - Page Not Found</h2>
        <Message>
          Oops! Looks like this page has taken a mystery turn. Mind clicking on
          a tab to the left and getting us back on track?
        </Message>
        <FunFact>
          Fun Fact: 404 errors were named after the room number where the
          original database with all the Internet's web addresses was stored.
          The room number? 404.
        </FunFact>
      </ContentBox>
    </Wrapper>
  );
};

export default Custom404;
